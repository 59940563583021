.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #0079D2;
  border-color: #0079D2;
}

.card-header-tabs {
  border-bottom: 2px solid #0079D2;
}
.tab-content .tab-pane {
  padding-top: 1.5rem;
}

.content-page {
  margin-left: 0 !important;
  padding: 65px 0px 0px !important;
}

.content {
  height: 100% !important;
}

.container-fluid {
  padding: 0px;
  height: 100% !important;
}

.navbar-custom {left: 0 !important;}

body {
  overflow: hidden !important;
}

#map-container {
  overflow-anchor:none;
}

